import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import introLogo from '../../assets/logo.png';
import background from '../../assets/background.svg'
class Intro extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        navigate: false,
        isLoading: false,
        navigate: false
    }
    

  
    
    
    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({ isLoading: false });
    //     }, 2000);
    // }


    handleSubmit = event => {
        event.preventDefault();
        this.setState({ navigate : true});
    };
    
    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
    }

    render() {

        const { isLoading } = this.state;

        return (
            <>

            
                { this.state.navigate && <Navigate to="/category" replace={true} /> }

                {
                    
                    isLoading ? 
                    <div className='intro' >
                            <div className="head">
                           
                                <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#4B1103" >
                                    <Skeleton height={150} width={150} circle={true} containerClassName="flex-1" />
                                </SkeletonTheme>
                            </div>

                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#4B1103" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>

                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#4B1103" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>
                        
                    </div>
                    : 
                    <div className='intro'>
                        <img src={background} alt="Background" className='backgroundImageIntro' />
                    <div className="head ani-stb-f">
                        <img src={introLogo} alt="" className='' />
                        {/* <h3>{this.settings.name}</h3> */}
                    </div>
                    <div className='t-b-intro'>
                  
                    <form onSubmit={this.handleSubmit} className=' ani-bts-f'>
                        <button className='English-button ' type='submit' onClick={() => this.handleChange('en')} > English </button>
                        <button type='submit' className='' onClick={() => this.handleChange('krd')} > کوردی </button>
                        <button type='submit' className='' onClick={() => this.handleChange('ar')}> عربی </button>
                        
                    </form>

                    <div className="social ani-stb-f">

                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }
                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }

                        { 
                            this.settings.titok
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><i className="fa-brands fa-facebook-f"></i></a>
                        }

                    </div>
                    </div>

                        
                    <div className="footerItro">
                        Developed By <a href='https://www.instagram.com/caviar.menu/' className='caviarIntor'>Caviar Menu</a>
                    </div>
                   
                </div>
    
                }

               
            </>
        );
    }
}

export default Intro;