import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import introLogo from '../../assets/logo.png';
import background from '../../assets/background.svg'

class Category extends Component {

    settings = this.props.Settings;

    state = {
        categories: [],
        load: false,
        back: false
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e.target.value);
        console.log(e.target.value);
        console.log(this.props.Language);
    }

    headCover = {'backgroundImage':`url('/assets/cover.jpg')`, 'backgroundSize':'cover'}
    categoryCover = {'backgroundSize': 'cover', 'backgroundPosition': 'center'}

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                 <img src={background} alt="Background" className='backgroundImageIntro' />

                <div className='category'>
                    <div className="head" style={this.headCover}>
                            
                            
                                                 
                    <img src={introLogo} alt="" className='' />

                    </div>
                
                    

                    

                    <div className="category-list ani-fade-in">


                            <Link to={"/menu/restaurant"} style={ { backgroundImage :`url(${process.env.PUBLIC_URL + 'assets/Restaurant.jpg'})`, ...this.categoryCover} } 
                              >
                                <h2> 
                                    {
                                        this.props.Language == "en" ? 'Restutant' : this.props.Language == "krd" ? 'خوارنگەهـ' : 'مطعم'
                                    }
                                </h2>
                            </Link>

                            <Link to={"/menu/coffee"} style={ { backgroundImage :`url(${process.env.PUBLIC_URL + 'assets/Coffe.jpg'})`, ...this.categoryCover} }>
                                <h2> 
                                    {
                                        this.props.Language == "en" ? 'Coffe shop' : this.props.Language == "krd" ? 'کافی' : 'کافی'
                                    }
                                </h2>
                            </Link>

                    </div>
                    <div className="social">

                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }
                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }

                        { 
                            this.settings.titok
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><i className="fa-brands fa-facebook-f"></i></a>
                        }

                    </div>
                    </div>

                        
                    <div className="footerItro">
                        Developed By <a href='https://www.instagram.com/caviar.menu/' className='caviarIntor'>Caviar Menu</a>
                    </div>

                
            </>
        );
    }
}

export default Category;