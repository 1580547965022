import React, { Component } from 'react';
import catLoad from '../../assets/catLoad.gif';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

class CatLoading extends Component {

    state = { 
        loading: true,
        onError: false,
     }

    img = this.props.Img;

    handleLoad = e => {
        this.setState({loading: false});
    }

    handleError = e => {
        this.setState({loading: false});
        this.setState({onError: true});
    }
    
    render() {
        return (
            <>
                {
                    this.state.loading && 
                    <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#4B1103">
                        <Skeleton height={28} width={28} circle={true} />
                    </SkeletonTheme>
                }
                <img 
                    style={this.state.onError ? {display: 'none'} : {}}
                    src={this.img} 
                    onLoad={this.handleLoad} 
                    onError={this.handleError} 
                    ></img>
                {
                    this.state.onError && 
                    <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#EEE">
                        <Skeleton height={28} width={28} circle={true} />
                    </SkeletonTheme>
                }
            </>
        );
    }
}

export default CatLoading;