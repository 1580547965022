import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';

class Category extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
            <Link to={"/menu/"  + this.data.id} className='categories-item' onClick={ () => this.props.handleCategory(this.data.id)} >
                
                <span className={`categories-name ${this.props.Active?'active':''}`}>
                
                <div>
                    {
                        
                        this.props.Language == "en" ? this.data.name_eng
                        : this.props.Language == "krd" ? this.data.name_krd
                        : this.data.name_ar
                        
                    }
                </div>
                </span>
            </Link>
        );
    }
}

export default Category;